import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const ForkArmsLayout = ({ LHImage, LHText, RHImage, RHText, Stamps, PDFs, title, stampText }) => {

    return (
        <div>
            <div className="flex justify-center flex-wrap fork-downloads">
            {PDFs.map(pdf => {
                    return (
                    <div className="mr-4" key={pdf.name}><a
                        href={pdf.publicURL}
                        target="_blank"
                        rel="noreferrer">                        
                        <p style={{fontSize:'11.5px'}} className="inline-block mb-0 mr-2 align-middle">{pdf.name}</p>
                        <StaticImage src="../images/icon_download.png" alt="PDF" />
                        <p className="dash-seperator inline-block mb-0 ml-4 align-middle">-</p>
                        </a>
                    </div>
                    )
                })      
            }
            </div>
            <div className="flex justify-between flex-wrap">
                <div className="w-1/2 px-8 bottom-left-edge">
                    <div className="w-auto border-2 border-grey">
                        <GatsbyImage
                            image={LHImage} alt="Forks"></GatsbyImage>
                        <div className="w-full border-t-2 border-grey bg-red text-white text-center text-4xl font-bold py-4">{title}</div>
                    </div>                    
                    <section className="list-disc mt-12" dangerouslySetInnerHTML={{ __html: LHText }}></section>
                </div>
                <div className="w-1/2 px-8 top-right-edge">
                    <section dangerouslySetInnerHTML={{ __html: RHText }}></section>
                    <GatsbyImage
                        image={RHImage} alt="Forks"></GatsbyImage>               
                    <div className="flex justify-around flex-wrap gap-x-0.5">
                        {Stamps.map(stamp => {
                            return (
                                <GatsbyImage
                                    className="w-16"
                                    key={stamp.name} image={stamp.childImageSharp.gatsbyImageData} alt="stamp"></GatsbyImage>
                            )
                        })} 
                        
                    </div>
                    <p className="text-l text-red font-bold text-center mt-4">{stampText}</p>
                </div>
            </div>
        </div>
    )
}

export default ForkArmsLayout